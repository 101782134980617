import React from 'react'
import { Link } from 'gatsby'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
library.add(faArrowRight)

const Header = ({ siteTitle }) => (
  <header className="site-header">
    <h1>
      <Link to="/">{siteTitle}</Link>
    </h1>
    <a className="main-site-link button" href="https://adamhollister.com">
      Blog site
      <FontAwesomeIcon icon="arrow-right" />
    </a>
  </header>
)

export default Header
