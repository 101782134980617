import React, { Component } from 'react'
import { graphql } from 'gatsby'

import Layout from '../layouts'
import PostsByCategory from '../components/PostsByCategory'
import Navigation from '../components/Navigation'

export default class Home extends Component {
  constructor(props) {
    super(props)
    this.state = { currentCategory: 'JS' }
  }

  render() {
    // A list of filtered WP categories
    const categories = this.props.data.allWordpressCategory.edges.filter(
      edge => {
        // Filter out empty categories
        if (edge.node.count === 0) return null
        // Return all remaining that arent "uncategorized"
        return edge.node ? edge.node.slug !== 'uncategorized' : null
      }
    )

    // A list of WP posts
    const posts = this.props.data.allWordpressPost.edges

    // Button click handler, sets currentCategory state
    const handleButtonClick = category => {
      this.setState({ currentCategory: category })
    }

    return (
      <Layout>
        <Navigation
          currentCategory={this.state.currentCategory}
          categories={categories}
          handleButtonClick={handleButtonClick}
        />
        <PostsByCategory posts={posts} category={this.state.currentCategory} />
      </Layout>
    )
  }
}

// Query list of categories
export const pageQuery = graphql`
  query {
    allWordpressPost {
      edges {
        node {
          title
          slug
          categories {
            name
          }
        }
      }
    }
    allWordpressCategory(sort: { fields: [name] }) {
      edges {
        node {
          name
          slug
          count
        }
      }
    }
  }
`
