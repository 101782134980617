import React from 'react'
import { Link } from 'gatsby'

const PostsByCategory = ({ category, posts }) => {
  return posts.map(
    post =>
      post.node.categories[0].name === category ? (
        <Link className="post-node" to={post.node.slug} key={post.node.slug}>
          <h3>{post.node.title}</h3>
        </Link>
      ) : null
  )
}

export default PostsByCategory
