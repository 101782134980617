import React from 'react'

const Navigation = ({ currentCategory, categories, handleButtonClick }) => (
  <nav className="category-selector">
    {categories.map(({ node }) => (
      <h2 key={node.name}>
        <button
          className={currentCategory === node.name ? 'active-category' : null}
          onClick={() => handleButtonClick(node.name)}
        >
          {node.name}
        </button>
      </h2>
    ))}
  </nav>
)

export default Navigation
