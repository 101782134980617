import React from 'react'
import Footer from '../components/footer'
import Header from '../components/header'

export default class DefaultLayout extends React.Component {
  render() {
    return (
      <>
        <Header siteTitle="Adam Hollister - Code snippets" />
        <div className="container">{this.props.children}</div>
        <Footer />
      </>
    )
  }
}
